<template>
  <div class="ltr">
    <CoolLightBox 
      :items="images" 
      :index="index"
      @close="index = null">
    </CoolLightBox>

    <div class="images-wrapper ltr">
     
     <v-row  class="mt-4 d-flex align-center justify-center conOfAllImages" >
        <v-col md="3" cols="12" class="pa-0">
          <div class="conOfoneimg"  @click="index = 0">
            <img class="image image1" src="@/assets/img1.png" alt="" >
            <img class="zoom" src="@/assets/zoom.svg">
          </div>
        </v-col>
     
             <v-col md="3" cols="12" class="pa-0">
               <div class="conOftwoimg">
            <img class="image2 image" src="@/assets/img4.png" alt="" @click="index = 1">
             <img class="zoom" src="@/assets/zoom.svg">
               </div>
        <div class="conOftwoimg">
            <img class="image2 image" src="@/assets/img3.png" alt="" @click="index = 2">
             <img class="zoom" src="@/assets/zoom.svg">
            </div>
             </v-col>
        
         <v-col md="3" cols="12" class="pa-0">
           <div class="conOfoneimg">
            <img class="image image1" src="@/assets/img02.png" alt="" @click="index = 3">
              <img class="zoom" src="@/assets/zoom.svg">
           </div>
         </v-col>
       
      </v-row>
      <v-row  class="mb-4 align-center justify-center conOfAllImages d-md-flex d-none" >
                <v-col md="3" cols="12" class="pa-0">
           <div class="conOfoneimg">
            <img class="image image1" src="@/assets/img02.png" alt="" @click="index = 3">
              <img class="zoom" src="@/assets/zoom.svg">
           </div>
         </v-col>
         
             <v-col md="3" cols="12" class="pa-0">
               <div class="conOftwoimg">
            <img class="image2 image" src="@/assets/img4.png" alt="" @click="index = 1">
              <img class="zoom" src="@/assets/zoom.svg">
               </div>
        <div class="conOftwoimg">
            <img class="image2 image" src="@/assets/img3.png" alt="" @click="index = 2">
              <img class="zoom" src="@/assets/zoom.svg">
            </div>
             </v-col>
                 <v-col md="3" cols="12" class="pa-0">
          <div class="conOfoneimg"  @click="index = 0">
            <img class="image1 image" src="@/assets/img1.png" alt="" >
            <img class="zoom" src="@/assets/zoom.svg">
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      images: [
        require('@/assets/img1.png'),
require('@/assets/img4.png'),
 require('@/assets/img3.png'),
 require('@/assets/img02.png'),
  require('@/assets/img02.png'),
   require('@/assets/img3.png'),
   require('@/assets/img4.png'),
        require('@/assets/img1.png'),
      ],
      index: null
    };
  },
};
</script>
<style lang="scss" scoped>
.images-wrapper{
    margin: 50px 0px;
}

.image1{
background-size: cover;
width: 100%;
height:300px;

}
.image:hover{
     -webkit-filter: brightness(60%);
     filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
.conOftwoimg{
   width: 100%;
height:150px; 
position: relative;
overflow: hidden;
}
.conOfoneimg{
   width: 100%;
height:300px; 
position: relative;
  overflow: hidden;
}
.conOfoneimg:hover,.conOftwoimg:hover{
   .zoom{
    display: block;
     transition: all 1s ease;
    }
  .image{
     -webkit-filter: brightness(60%);
     filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
 
  transform: scale(1.3);
 
}
}
.zoom{
    position: absolute;
    left: 50%;
    top: 37%;
    width: 70px;
    transform: translate(-50%);
    display: none;
}
.image2{
    background-size: cover;
width: 100%;
height:150px;
}
.ltr{
  direction: ltr;
}
</style>